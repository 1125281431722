@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased text-black bg-yellow-light;
  }

  h1,
  .h1 {
    @apply text-purple;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
  }

  h2,
  .h2 {
    @apply text-purple;
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
  }

  h3,
  .h3 {
    @apply text-purple;
    font-size: 30px;
    line-height: 35px;
    font-weight: 700;
  }

  h4,
  .h4 {
    @apply text-purple;
    font-size: 21px;
    line-height: 26px;
    font-weight: 700;
  }

  h5,
  .h5 {
    @apply text-purple;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  @media (min-width: 1024px) {

    h1,
    .h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

@layer components {
  .btn {
    @apply transition-all rounded-full px-6 py-3 font-medium text-center border;
  }

  .btn-sm {
    @apply transition-all rounded-full px-4 py-2 font-medium text-sm text-center border;
  }

  .btn-purple {
    @apply bg-purple text-white border-purple;
  }

  .btn-purple:hover {
    @apply bg-purple/80;
  }

  .btn-red {
    @apply bg-red text-white border-red;
  }

  .btn-red:hover {
    @apply bg-red/80;
  }

  .btn-invert {
    @apply bg-white text-purple border-purple;
  }

  .btn-invert:hover {
    @apply bg-purple text-white;
  }

  .scroll-bar {
    overflow: hidden;
    overflow-x: auto;
  }
}

@layer utilities {}

.loading {
  animation: animate-color 4s linear infinite;
}

@keyframes animate-color {
  0% {
    color: #59017A;
  }

  40% {
    color: #00A7CF;
  }

  60% {
    color: #96FB1E;
  }

  80% {
    color: #00A7CF;
  }

  100% {
    color: #59017A;
  }
}

#google_translate_element {
  display: flex;
  align-items: center;
}

#google_translate_element .goog-te-gadget {
  font-size: 0px !important;
}

#google_translate_element .goog-te-gadget span {
  display: none !important;
}

#google_translate_element .goog-te-gadget select {
  border: none !important;
  margin: 0 !important;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem !important;
}